<template>
  <div class="propertydashboard__body">
    <div class="bodyheader">
      <router-link to="/" class="mobile-back">
        <i class="fas fa-chevron-left" />
      </router-link>
      <h4 v-if="!isLoading">{{ propertyData.address.fullAddress }}</h4>
      <div class="bodyheader__menu">
        <a href="#" class="bodyheader__menuitem active">propostas</a>
        <a
          href="#"
          class="bodyheader__menuitem bodyheader__menuitem--mobile active"
        >
          <i class="fas fa-clipboard-check" />
        </a>
      </div>
    </div>
    <div v-if="isLoading" class="loading" />
    <div v-else class="bodycontent">
      <div class="proposal__details proposal__card">
        <h3>proposta: {{ bidData.id }}</h3>
        <span style="display: block;" />
        <h4>interessado</h4>
        <div class="line-divider" />
        <div class="proposal__detailscontainer--users">
          <div class="proposal__detailscontainer">
            <span>
              <i class="fas fa-dollar-sign" />
              <p>{{ bidData.client && bidData.client.name }}</p>
            </span>
          </div>
          <div class="proposal__detailscontainer">
            <span>
              <i class="fas fa-envelope" />
              <p>
                {{
                  bidData.client && bidData.client.phone
                    ? formatPhone(bidData.client.phone)
                    : ""
                }}
              </p>
            </span>
            <span>
              <i class="fas fa-phone" />
              <p>{{ bidData.client && bidData.client.email }}</p>
            </span>
          </div>
        </div>

        <h4>condições</h4>
        <div class="line-divider" />
        <div class="proposal__detailscontainer">
          <p>{{ bidData.details }}</p>
        </div>

        <h4>resposta</h4>
        <div class="line-divider" />
        <div class="proposal__detailscontainer">
          <p>{{ bidData.companyResponse }}</p>
        </div>

        <h4>status</h4>
        <div class="line-divider" />
        <div class="proposal__detailscontainer">
          <p>
            {{ bidData.status }} -
            {{ bidData.updatedAt | simpleDateComplete }}
          </p>
        </div>

        <h4>tempo de locação</h4>
        <div class="line-divider" />
        <div class="proposal__detailscontainer">
          <p v-if="bidData.rentalTime">
            {{ bidData.rentalTime }}
            {{ bidData.rentalTime > 1 ? "meses" : "mês" }}
          </p>
          <p v-else>
            não informado
          </p>
        </div>

        <h4>garantia</h4>
        <div class="line-divider" />
        <div class="proposal__detailscontainer">
          <p v-if="bidData.warrantyName && bidData.warrantyName.length">
            {{ bidData.warrantyName }}
          </p>
          <p v-else>
            não informado
          </p>
        </div>
      </div>

      <div class="proposal__values proposal__card">
        <h3>valores</h3>
        <div class="proposal__detailscontainer client">
          <h1>R$ {{ bidData.value }},00</h1>
          <p class="pricedetails">
            valor final a ser pago
          </p>
        </div>
      </div>
      <div class="proposal__actions">
        <button-component
          v-if="bidData.status === 'Aguardando cliente'"
          design-type="default"
          font-size="16px"
          type="submit"
          :padding-v="'30px'"
          :padding-h="'15px'"
          @click="acceptConfirmationOpen = true"
        >
          aceitar estas condições
        </button-component>
        <button-component
          v-if="
            bidData.status === 'Aguardando cliente' ||
              bidData.status === 'Recusada'
          "
          design-type="default-white"
          font-size="16px"
          type="submit"
          :padding-v="'45px'"
          :padding-h="'14px'"
          @click="counterOfferOpen = true"
        >
          fazer contraproposta
        </button-component>
        <button-component
          v-if="
            bidData.status === 'Aguardando proprietário' ||
              bidData.status === 'Aguardando cliente'
          "
          design-type="white-cancel"
          font-size="16px"
          type="submit"
          :padding-v="'30px'"
          :padding-h="'14px'"
          @click="declineConfirmationOpen = true"
        >
          desistir desta proposta
        </button-component>
      </div>
      <div class="footer">
        Valor aluguel líquido para pagamento até o vencimento, sem encargos
        (Condomínio, iptu, seguro)
      </div>

      <!-- SECTION modal -->
      <Modal
        :is-open="acceptConfirmationOpen"
        class="acceptConfirmationModal"
        @close-modal="acceptConfirmationOpen = false"
      >
        <template v-if="!offerAccepted">
          <p class="modaltitle">
            Aceitar a proposta?
          </p>
          <div class="attention-block active">
            <h3>ATENÇÃO</h3>
            <div class="attention-inner-block">
              <div class="attention-icon">
                <i class="fa fas-ruler" />
              </div>
              <p>
                - Certifique-se que todos os dados da proposta estão corretos
                antes de aceitá-la.
              </p>
            </div>
            <div class="attention-inner-block">
              <div class="attention-icon">
                <i class="fa fas-ruler" />
              </div>
              <p>
                - Ao aceitar a proposta a RuaDois dará início ao processo de
                coletar a documentação para o aluguel do imóvel.
              </p>
            </div>
          </div>
          <checkbox
            v-model="termAcceptance"
            label-text="Aceito todas as condições informadas desta proposta"
            required
          />
          <div class="button-container">
            <div class="modalbuttoncontainer">
              <button-component
                design-type="default"
                font-size="18px"
                :disabled="termAcceptance ? false : true"
                :padding-v="'35px'"
                :padding-h="'14px'"
                @click="acceptProposal"
              >
                aceito todas essas condições
              </button-component>
              <button-component
                design-type="default-white"
                font-size="18px"
                :padding-v="'35px'"
                :padding-h="'14px'"
                @click="acceptConfirmationOpen = false"
              >
                rever detalhes
              </button-component>
            </div>
          </div>
        </template>
        <template v-if="offerAccepted">
          <p class="modaltitle">
            Proposta aceita
          </p>
          <p class="modalsubtitle">
            Você aceitou uma proposta no valor de
            <b>R$ {{ bidData.value }},00</b>
            para o seu imóvel. Agora, iniciaremos o processo de coletar a
            documentação para o aluguel do imóvel.
          </p>
          <div class="modalbuttoncontainer">
            <button-component
              design-type="default"
              block
              font-size="16px"
              :icon="'fas fa-arrow-left'"
              :icon-position="'left'"
              @click="acceptConfirmationOpen = false"
            >
              concluir
            </button-component>
          </div>
        </template>
      </Modal>

      <!-- SECTION modal -->
      <Modal
        :is-open="counterOfferOpen"
        class="counterOfferModal"
        @close-modal="counterOfferOpen = false"
      >
        <template v-if="!counterOfferSent">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(sendCounterOffer)">
              <p class="modaltitle">
                Fazer contraproposta
              </p>
              <div class="newvaluecontainer">
                <div class="newvaluecontainer__input--comment">
                  <currency-field
                    v-model="newValue"
                    label="Novo valor"
                    name="novo valor"
                    rules="required"
                    :placeholder="'R$ 1000,00'"
                  />

                  <text-field
                    v-model="comments"
                    label="Observação"
                    name="observação"
                    :autocomplete="'off'"
                    :placeholder="'comentários'"
                    :type="'text'"
                  />
                  <text-field
                    v-model="rentalTime"
                    label="Tempo de locação (em meses)"
                    name="bidRentalTime"
                    :autocomplete="'off'"
                    :type="'number'"
                  />

                  <text-field
                    v-model="warrantyName"
                    label="Garantia da proposta"
                    name="bidWarrantyName"
                    :autocomplete="'off'"
                    :type="'text'"
                  />
                </div>
              </div>
              <div class="modalbuttoncontainer">
                <button-component
                  design-type="default"
                  font-size="17px"
                  block
                  type="submit"
                >
                  enviar
                </button-component>
                <button-component
                  design-type="default-white"
                  font-size="17px"
                  block
                  type="reset"
                  @click="counterOfferOpen = false"
                >
                  rever detalhes
                </button-component>
              </div>
            </form>
          </ValidationObserver>
        </template>
        <template v-if="counterOfferSent">
          <p class="modaltitle">
            Contraproposta Enviada
          </p>
          <p class="modalsubtitle">
            Você enviou uma contraproposta no valor de
            <b>R$ {{ parseInt(newValue) }},00</b>.
          </p>
          <div class="modalbuttoncontainer">
            <button-component
              design-type="default"
              block
              font-size="16px"
              :icon-position="'left'"
              :icon="'fas fa-arrow-left'"
              @click="counterOfferOpen = false"
            >
              concluir
            </button-component>
          </div>
        </template>
      </Modal>

      <!-- SECTION modal -->
      <Modal
        :is-open="declineConfirmationOpen"
        class="declineConfirmationModal"
        @close-modal="declineConfirmationOpen = false"
      >
        <template v-if="!offerDeclined">
          <p class="modaltitle">
            Recusar a proposta?
          </p>
          <div class="attention-block active">
            <h3>ATENÇÃO</h3>
            <div class="attention-inner-block">
              <div class="attention-icon">
                <i class="fa fas-ruler" />
              </div>
              <p>
                Ao recusar a proposta, o cliente será avisado que a negociação
                foi finalizada.
              </p>
            </div>
          </div>
          <div class="modalbuttoncontainer">
            <div>
              <button-component
                design-type="white-cancel"
                font-size="17px"
                block
                type="reset"
                :padding-v="'35px'"
                :padding-h="'14px'"
                @click="declineProposal"
              >
                recusar a proposta
              </button-component>
              <button-component
                design-type="default"
                font-size="17px"
                block
                type="reset"
                :padding-v="'35px'"
                :padding-h="'14px'"
                @click="declineConfirmationOpen = false"
              >
                rever detalhes
              </button-component>
            </div>
          </div>
        </template>
        <template v-if="offerDeclined">
          <p class="modaltitle">
            Proposta Finalizada
          </p>
          <p class="modalsubtitle">
            Você recusou a proposta.
          </p>
          <div class="modalbuttoncontainer">
            <button-component
              design-type="default"
              block
              font-size="16px"
              :icon="'fas fa-arrow-left'"
              :icon-position="'left'"
              @click="declineConfirmationOpen = false"
            >
              concluir
            </button-component>
          </div>
        </template>
      </Modal>
    </div>
  </div>
</template>
<script>
import Modal from '../../../components/ui/Modal'
import { formatPhone } from '@/assets/js_helpers/_string-ultil'
import { simpleDateComplete } from '@/filters/formatDates'
import * as bidAPI from '@/api/bid'
import * as propertyAPI from '@/api/property'
import { mapGetters } from 'vuex'

export default {
  name: 'BidDetailsRenter',
  components: {
    Modal
  },
  filters: {
    simpleDateComplete
  },
  data: function () {
    return {
      isLoading: false,
      bidData: {},
      propertyData: {},
      acceptConfirmationOpen: false,
      counterOfferOpen: false,
      declineConfirmationOpen: false,
      newValue: '0',
      comments: '',
      offerDeclined: false,
      counterOfferSent: false,
      offerAccepted: false,
      termAcceptanceStatus: false,
      bidRentalTime: 0,
      bidWarrantyName: ''
    }
  },
  computed: {
    ...mapGetters({
      userGetter: 'user/userGetter',
      configGetter: 'company/configGetter'
    }),
    termAcceptance: {
      get () {
        return this.termAcceptanceStatus
      },
      set (val) {
        this.termAcceptanceStatus = val.isChecked
      }
    }
  },
  watch: {
    acceptConfirmationOpen () {
      this.offerAccepted = false
      this.loadData()
    },
    counterOfferOpen () {
      this.counterOfferSent = false
      this.loadData()
    },
    declineConfirmationOpen () {
      this.offerDeclined = false
      this.loadData()
    }
  },
  async created () {
    this.isLoading = true

    await this.loadData()
    this.isLoading = false
  },
  methods: {
    formatPhone,
    async loadData () {
      await Promise.all([
        bidAPI
          .getBidByIdClient({
            bidId: this.$route.params.bidId,
            includeClient: true
          })
          .then(async response => {
            this.bidData = response.data
            this.rentalTime = response.data.rentalTime || 0
            this.warrantyName = response.data.warrantyName || ''
          }),

        propertyAPI
          .byIdClient({
            id: this.$route.params.propertyId,
            includeAddress: true
          })
          .then(async response => {
            if (response && response.data) {
              this.propertyData = response.data
            }
          })
      ])
    },
    goBack () {
      this.$router.go(-1)
    },
    acceptProposal () {
      if (!this.termAcceptance) {
        return
      }

      bidAPI
        .updateBidClient({
          bidId: this.$route.params.bidId,
          status: 'Aceita',
          details: ''
        })
        .then(response => {
          this.offerAccepted = true
        })
    },
    sendCounterOffer () {
      bidAPI
        .updateBidClient({
          bidId: this.$route.params.bidId,
          status: 'Aguardando proprietário',
          details: this.comments,
          value: parseInt(this.newValue)
        })
        .then(response => {
          this.counterOfferSent = true
        })
    },
    declineProposal () {
      bidAPI
        .updateBidClient({
          bidId: this.$route.params.bidId,
          status: 'Recusada',
          details: ''
        })
        .then(response => {
          this.offerDeclined = true
        })
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
@mixin absolute-center-xy() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading {
  @include absolute-center-xy;
}

.propertydashboard__body {
  // SECTION responsive
  @include respond-to(tablet-portrait-down) {
    border-radius: 0;
    box-shadow: none;
    height: auto;

    .bodyheader {
      border-radius: 0;

      .mobile-back {
        display: block;
        position: absolute;
        padding-top: 38px;
        padding-left: 15px;
        color: #fff;
        font-size: 22px;
      }

      h4 {
        padding-right: 35px;
        padding-left: 35px;
        height: 98px;
        text-align: center;
        line-height: 17px;
        letter-spacing: -0.02em;
        color: #fff;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
      }

      &__menu {
        width: 100%;

        a {
          display: none;
        }

        a.bodyheader__menuitem--mobile {
          display: flex;
          border-bottom: 4px solid rgba(255, 255, 255, 0);
        }

        a.bodyheader__menuitem--mobile.active,
        a.bodyheader__menuitem--mobile:hover {
          transition: border-bottom 0.3s ease-in-out;
          border-bottom: 4px solid #fff;
          background: none;
        }
      }
    }

    .bodycontent {
      h2.bodycontent__header--desktop {
        display: none;
      }

      h2.bodycontent__header--mobile {
        display: block;
        transform: none;
        margin-bottom: 20px;
        margin-left: 0;
        border-bottom: 0;
        padding-left: 15px;
        text-align: left;
        line-height: normal;
        letter-spacing: -0.02em;
        color: #2d5aa0;
        font-size: 24px;
        font-weight: bold;
        font-style: normal;
      }
    }
  }
  border-radius: 10px;
  box-shadow: 8px 8px 55px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 0;
  padding-bottom: 50px;
  min-height: calc(100vh - 160px);

  &:-webkit-scrollbar {
    background: #fff;
    width: 0.5em !important;
    height: 0.5em !important;
  }

  .bodyheader {
    transition: all 0.2s;
    border-radius: 10px 10px 0 0;
    background: linear-gradient(
      150deg,
      rgba(1, 65, 132, 1) 0%,
      rgba(0, 52, 105, 1) 65%
    );
    padding: 1rem 0 0;
    width: 100%;

    .mobile-back {
      display: none;
    }

    h4 {
      box-shadow: 0 10px 9px -5px rgba(0, 0, 0, 0.24);
      padding: 32px 0;
      text-align: center;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: #fff;
      font-size: 26px;
      font-weight: normal;
      font-style: normal;
    }

    &__menu {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: auto;
      background: #00448a;
      width: 100%;
      height: 53px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.3s ease-in-out;
        width: 20%;
        height: 53px;
        text-align: center;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: #fff;
        font-size: 18px;
        font-weight: 300;
        font-style: normal;
      }

      a.bodyheader__menuitem--mobile {
        display: none;
      }

      a:hover,
      a.active {
        transition: background-color 0.3s ease-in-out;
        background: rgba(0, 0, 0, 0.3);
      }
    }
  }

  .bodycontent {
    h2 {
      display: inline-block;
      transform: translateX(-50%);
      margin-bottom: 56px;
      margin-left: 50%;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 10px;
      text-align: center;
      line-height: normal;
      letter-spacing: -0.04em;
      color: #333;
      font-size: 24px;
      font-weight: bold;
      font-style: normal;
    }

    h2.bodycontent__header--mobile {
      display: none;
    }
  }

  .footer {
    @include respond-to(tablet-portrait-down) {
      position: relative;
      bottom: 0;
      left: 0%;
    }
    position: relative;
    top: 40px;
    left: 0;
    text-align: center;
    line-height: 177.27%;
    letter-spacing: -0.02em;
    color: #5f5f5f;
    font-family: Raleway;
    font-size: 13.5px;
    font-weight: normal;
    font-style: normal;
  }
}

.propertydashboard__body::-webkit-scrollbar {
  width: 0 !important;
}

.propertydashboard__body::-webkit-scrollbar-thumb {
  border-radius: 3px !important;
  background-color: rgba(45, 90, 160, 0.8) !important;
}

.proposal {
  &__card {
    @include respond-to(tablet-portrait-down) {
      padding: 20px 10px;
      width: 100%;
    }
    display: inline-block;
    padding: 30px 25px;
    width: 49%;
    vertical-align: top;

    &:first-child {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }

    h3 {
      display: inline-block;
      margin: 0;
      border-bottom: 3px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 15px;
      line-height: 16px;
      color: #333;
      font-size: 28px;
      font-weight: normal;
      font-style: normal;
    }

    h4 {
      display: inline-block;
      position: relative;
      z-index: 50;
      margin: 0;
      margin-top: 15px;
      background: #fff;
      padding-right: 13px;
      line-height: 27px;
      color: #444;
      font-size: 15px;
      font-weight: 700;
    }

    .line-divider {
      margin: 12px 0;
      margin-top: -13px;
      margin-bottom: 20px;
      background: #eee;
      width: 100%;
      height: 2px;
    }
  }

  &__timeline {
    display: block;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
    background: #fff;
    padding-top: 10.5px;
    width: 100%;
    height: 40px;
    text-align: center;

    &block {
      display: inline-block !important;
      text-transform: uppercase;
      line-height: normal;
      color: rgba(0, 0, 0, 0.25);
      font-size: 14px;
      font-weight: bold;
      font-style: normal;

      .dot {
        display: inline-block;
        margin-right: 4px;
        margin-bottom: 2px;
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.25);
        width: 8px;
        height: 8px;
        vertical-align: middle;
      }

      .dashed {
        margin-left: 4px;
        width: 80px;
        vertical-align: middle;
      }
    }

    &block.show-phone-only {
      display: none !important;
    }

    &block--active {
      color: #2d5aa0;

      .dot {
        background-color: #2d5aa0;
      }
    }
  }

  &__values {
    @include respond-to(tablet-portrait-down) {
      height: auto;
    }

    h1 {
      line-height: 47px;
      letter-spacing: -1px;
      color: #666;
      font-size: 36px;
      font-weight: bold;
      font-style: normal;
    }

    p.pricedetails {
      margin-bottom: 10px;
      color: rgba(102, 102, 102, 0.5);
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
    }
  }

  &__reply {
    display: block;
    transition: transform 0.3s ease-in-out;
    margin: auto;
    margin-top: 20px;
    border: 1px solid #00448a;
    border-radius: 40px;
    background: #fff;
    cursor: pointer;
    padding: 15px 20px;
    width: 280px;
    max-width: 100%;
    text-align: center;
    text-transform: lowercase;
    color: #00448a;

    &:hover {
      transform: scale(1.08);
      transition: transform 0.3s ease-in-out;
    }
  }

  &__detailscontainer {
    padding-top: 0;
    min-width: 0;

    p {
      margin-right: 10px;
      margin-left: 10px;
      font-size: 14px;
    }

    span {
      display: flex;
    }

    i {
      color: #b1b1b1;
    }

    &--red-arrow {
      i {
        color: #d70505;
      }
    }

    &--green-arrow {
      i {
        color: #05d733;
      }
    }

    &--users {
      @include respond-to(phone-only) {
        grid-template-columns: 1fr;
      }
      display: grid;
      grid-template-columns: 1.5fr 2fr;
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        overflow: visible;
        white-space: normal;
        word-break: break-all;
        word-wrap: break-word;
      }
    }

    &.client {
      padding-top: 65px;
      padding-left: 10px;
    }
  }

  &__valuescontainer {
    @include respond-to(tablet-portrait-down) {
      display: block;
      float: none;
      padding: 39px 15px;
      width: 100%;
    }

    display: inline-block;
    background: none;
    background-image: url("~@/assets/img/dashboard/Union.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 39px 30px;
    padding: 0 10px;
    padding-top: 47px;
    width: 345px;
    width: 280px;
    max-width: 100%;

    &line {
      span:nth-child(1) {
        display: inline-block;
        width: 145px;
        color: #666;
        font-size: 15px;
        font-weight: bold;
        font-style: normal;
      }

      span:nth-child(2) {
        float: right;

        i.fa-plus-circle {
          color: #2d5aa0;
        }

        i.fa-minus-circle {
          color: #f0465a;
        }
      }
    }

    &line:nth-child(1) {
      margin-bottom: 20px;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin: 0 auto;
    padding-top: 30px;
    width: 45%;
    min-width: 35%;

    .button-component + .button-component {
      margin-top: 15px;
    }
  }
}

@include respond-to(tablet-portrait-down) {
  .proposal__timeline {
    &block.show-desktop-only {
      display: none !important;
    }

    &block.show-phone-only {
      display: inline-block !important;
      font-size: 12px;

      .dashed {
        width: 15px;
      }
    }
  }
}

.modaltitle {
  margin: 0;
  padding-bottom: 20px;
  text-align: center;
  line-height: normal;
  letter-spacing: -0.03em;
  color: #444;
  font-size: 28px;
  font-weight: 700;
}

.modalsubtitle {
  text-align: center;
  font-size: 16px;
}

.modalbuttoncontainer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 20px;
  width: 60%;

  .button-component + .button-component {
    margin-top: 10px;
  }
}

.newvaluecontainer {
  margin-top: 20px;

  &__input {
    display: inline-block;

    .text-field {
      text-align: center;
    }

    &--comment {
      justify-self: center;
      margin-right: 10%;
      margin-left: 10%;
    }
  }
}

.attention-block {
  margin: 0;
  border-radius: 20px;
  background: #f2f2f2;
  padding: 24px 35px;
  line-height: normal;
  letter-spacing: -0.03em;
  color: #444;
  font-weight: bold;

  h3 {
    font-size: 24px;
  }

  p {
    letter-spacing: normal;
    font-size: 14px;
    font-weight: normal;
  }
}

.checkbox {
  padding: 15px 0;
  font-size: 16px;
}
</style>
