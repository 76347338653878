import * as http from './http'

export const list = function (data, config) {
  const URL = `/properties/${data.id}/bids`

  return http.get(URL, config)
}

export const getBids = function (data, config) {
  let URL = '/bids'

  if (data && Object.keys(data).length) {
    URL += '?' + (new URLSearchParams(data).toString())
  }
  return http.get(URL, config)
}

export const getBidById = function (data, config) {
  let URL = `/bids/${data.bidId}`

  if (data && Object.keys(data).length) {
    delete data.bidId
    URL += '?' + (new URLSearchParams(data).toString())
  }
  return http.get(URL, config)
}

export const getBidByIdClient = function (data, config) {
  let URL = `/bids-client/${data.bidId}`

  if (data && Object.keys(data).length) {
    delete data.bidId
    URL += '?' + (new URLSearchParams(data).toString())
  }
  return http.get(URL, config)
}

export const updateBid = function (data, config) {
  const URL = `/bids/${data.bidId}`
  let body = {}

  if (Object.keys(data).length) {
    delete data.bidId
    body = data
  }
  return http.put(URL, body, config)
}

export const updateBidClient = function (data, config) {
  const URL = `/bids/${data.bidId}`
  let body = {}

  if (Object.keys(data).length) {
    delete data.bidId
    body = data
  }
  return http.put(URL, body, config)
}

export const createBid = function (data, config) {
  let body = {}

  if (Object.keys(data).length) {
    body = data
  }

  return http.post('/bids', body, config)
}

export const createBidClient = function (data, config) {
  let body = {}

  if (Object.keys(data).length) {
    body = data
  }

  return http.post('/bids-client', body, config)
}
